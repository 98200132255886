import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import About from './About'
import reportWebVitals from './reportWebVitals';
import '@fontsource/metropolis/100.css';
import '@fontsource/metropolis/400.css';
import '@fontsource/metropolis/500.css';
import '@fontsource/metropolis/700.css';
import {Link, Route} from "wouter";
import Portfolio from "./Portfolio";
import MapApp from "./MapApp";

const Router = () => (
    <div>
        <Route path="/" component={App}/>
        <Route path="/about" component={About}/>
        <Route path="/projects/personal_website" component={Portfolio}/>
        <Route path="/projects/map_app" component={MapApp}/>
    </div>
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Router/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
